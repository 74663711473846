
































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem51LBPostLab4DiscussionConclusion',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        discussionAnswer: null,
        conclusionAnswer: null,
      },
      questions: [
        {
          text: 'In the space below, please write your discussion.',
          inputName: 'discussionAnswer',
          maxInputLength: 4500,
        },
        {
          text: 'In the space below, please write your conclusion.',
          inputName: 'conclusionAnswer',
          maxInputLength: 1000,
        },
      ],
    };
  },
});
